import React from "react";

const Footer = () => {
  return (
    <footer className="bg-primary-blue text-white h-24 flex items-center justify-center py-4 sticky w-full">
    &copy; 2024 Seu Site - Todos os direitos reservados
  </footer>
  );
};

export default Footer;
